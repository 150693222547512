import React from "react"
import { graphql } from "gatsby"
import { ContentLayout } from "layouts/Content/index.layout"
import { Paragraph as P } from "components/Paragraph/styles"
import { Grid, GridItem } from "components/Grid"
import { Image } from "components/Image"
import { Text } from "components/Text"
import { color } from "common/settings"

interface Props {
  location: Location
  data: any
}

const PageBuilder: React.FC<Props> = props => {
  const {
    data: { article },
  } = props

  return (
    <ContentLayout
      inverted={false}
      mainHeaderPosAbsolute={false}
      lang={"en"}
      location={props.location}
      title={""}
      description={""}
    >
      <Grid
        modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
        spacingTop={"xxl"}
        spacingBottom={"xxl"}
      >
        <GridItem responsive={{ lg: { start: 2, span: 10 } }}>
          <div style={{ width: "180px", marginBottom: 64 }}>
            <Image src={"zero-emissions-logo-magazine.png"} alt={"Logo"} />
            {Text({
              modifiers: ["title", "color"],
              color: "highlight",
              children: "Magazine",
            })}
          </div>
          <P.Block
            modifiers={["singleArticle"]}
            dangerouslySetInnerHTML={{ __html: article.content }}
          />
        </GridItem>
        {/* <Paragraph
      modifiers=[]
        options={{
          reverse: false,
          wide: true,
          paddingTop: "xl",
          paddingBottom: "xl",
        }}
        body={{ paragraphI: article.content }}
      /> */}
      </Grid>
    </ContentLayout>
  )
}

export default PageBuilder

export const data = graphql`
  query ($slug: String!) {
    article: docx(name: { eq: $slug }) {
      name
      content
    }
  }
`
